import * as reducers from 'src/reducers'

const INITIAL_ROOT_STATE = {
  notifications: reducers.INITIAL_NOTIFICATIONS_STATE,
  vigilance: reducers.INITIAL_VIGILANCE_STATE,
}

export default INITIAL_ROOT_STATE

export type RootState = typeof INITIAL_ROOT_STATE
