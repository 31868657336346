import { HIDE_TIMEOUT_NOTIFICATION, SHOW_TIMEOUT_NOTIFICATION } from 'src/action-creators/vigilance'

import createReducer from './createReducer'

export interface VigilanceState {
  showTimeoutNotification: boolean
}

export const INITIAL_VIGILANCE_STATE: VigilanceState = {
  showTimeoutNotification: false,
}

const showTimeoutNotification = (state: VigilanceState) => ({
  ...state,
  showTimeoutNotification: true,
})

const hideTimeoutNotification = (state: VigilanceState) => ({
  ...state,
  showTimeoutNotification: false,
})

export const reduceVigilance = createReducer(INITIAL_VIGILANCE_STATE, {
  [HIDE_TIMEOUT_NOTIFICATION]: hideTimeoutNotification,
  [SHOW_TIMEOUT_NOTIFICATION]: showTimeoutNotification,
})

export default reduceVigilance
