import { combineReducers } from 'redux'

import { RootState } from 'src/store/initialStore'

import notifications from './notifications'
import reduceVigilance from './vigilance'

const rootReducer = combineReducers<RootState>({
  notifications,
  vigilance: reduceVigilance,
})

export default rootReducer
