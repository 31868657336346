import { AppLocale } from '@procsea/common/utils'
import { CountryCode } from '@procsea/design-system'

import {
  InvoiceCycle,
  OrderItemStatus,
  OrderItemsSortBy,
  OrderStatus,
  Quality,
  QualityColor,
  QualityName,
  QualityVerbose,
  UnitId,
  UserType,
} from 'src/types'

/**
 * Every constants (configuration) of ProcSea React App
 */

export const PACKAGING_UNIT_IDS = [UnitId.KG, UnitId.PIECE]
export const UNIT_IDS_WITH_PACKAGINGS = [
  UnitId.BASKET,
  UnitId.PACKAGE,
  UnitId.BIG_BAG,
  UnitId.BIN,
  UnitId.SACK,
]

/**
 * @deprecated
 * Use getUnitName() instead.
 */

export const getPluralTranslation = (name: string, quantity?: number) => {
  switch (name) {
    case 'bourriche':
    case 'Basket':
      return ngettext('basket', 'baskets', quantity)
    case 'boxes':
      return ngettext('box', 'boxes', quantity)
    case 'pcs':
    case 'Piece':
      return ngettext('piece', 'pieces', quantity)
    case 'colis':
    case 'Package':
      return ngettext('package', 'packages', quantity)
    case 'bin':
    case 'Bin':
      return ngettext('bin', 'bins', quantity)
    case 'bigbag':
    case 'Bigbag':
      return ngettext('bigbag', 'bigbags', quantity)
    case 'sack':
    case 'Sack':
      return ngettext('sack', 'sacks', quantity)
    case 'kg':
    case 'Kg':
      return ngettext('kg', 'kg', quantity)
    default:
      return name
  }
}

/**
 * @deprecated
 * Qualities are handled by the theme in design-system.
 * Use <Quality /> component to display them, and QualityType for types.
 */
export const QUALITY_EXCEPTIONAL: Quality = {
  id: 0,
  name: QualityName.EXCEPTIONAL,
  verbose: QualityVerbose.EXCEPTIONAL,
  color: QualityColor.GREEN,
}

/**
 * @deprecated
 * Qualities are handled by the theme in design-system.
 * Use <Quality /> component to display them, and QualityType for types.
 */
export const QUALITY_EXTRA: Quality = {
  id: 1,
  name: QualityName.EXTRA,
  verbose: QualityVerbose.EXTRA,
  color: QualityColor.LIGHTGREEN,
}

/**
 * @deprecated
 * Qualities are handled by the theme in design-system.
 * Use <Quality /> component to display them, and QualityType for types.
 */
export const QUALITY_GOOD: Quality = {
  id: 2,
  name: QualityName.GOOD,
  verbose: QualityVerbose.GOOD,
  color: QualityColor.OLIVE,
}

/**
 * @deprecated
 * Qualities are handled by the theme in design-system.
 * Use <Quality /> component to display them, and QualityType for types.
 */
export const QUALITY_MEDIUM: Quality = {
  id: 3,
  name: QualityName.MEDIUM,
  verbose: QualityVerbose.MEDIUM,
  color: QualityColor.AMBER,
}

/**
 * @deprecated
 * Qualities are handled by the theme in design-system.
 * Use <Quality /> component to display them, and QualityType for types.
 */
export const QUALITY_LOW: Quality = {
  id: 4,
  name: QualityName.LOW,
  verbose: QualityVerbose.LOW,
  color: QualityColor.ORANGE,
}

/**
 * @deprecated
 * Qualities are handled by the theme in design-system.
 * Use <Quality /> component to display them, and QualityType for types.
 */
export const PROCSEA_QUALITIES = [
  QUALITY_EXCEPTIONAL,
  QUALITY_EXTRA,
  QUALITY_GOOD,
  QUALITY_MEDIUM,
  QUALITY_LOW,
]

export type OrderStatusMapper = { [key in OrderStatus]: string }

export const orderStatusI18nMapper: OrderStatusMapper = {
  [OrderStatus.CANCELLED]: gettext('Cancelled'),
  [OrderStatus.DRAFT]: gettext('Draft'),
  [OrderStatus.INVOICED]: gettext('Invoiced'),
  [OrderStatus.INVOICING]: gettext('Awaiting invoicing'),
  [OrderStatus.PENDING]: gettext('Pending'),
  [OrderStatus.TO_PREPARE]: gettext('To prepare'),
  [OrderStatus.VALIDATED]: gettext('Validated'),
}

export const getOrderStatusTranslation = (status: OrderStatus) => orderStatusI18nMapper[status]

export type OrderItemStatusMapper = { [key in OrderItemStatus]: string }

export const orderItemStatusI18nMapper: OrderItemStatusMapper = {
  [OrderItemStatus.ACCEPTED]: gettext('Accepted'),
  [OrderItemStatus.CANCELLED]: gettext('Cancelled'),
  [OrderItemStatus.REJECTED]: gettext('Rejected'),
  [OrderItemStatus.PENDING]: gettext('Pending'),
}

export const getOrderItemStatusTranslation = (status: OrderItemStatus) =>
  orderItemStatusI18nMapper[status]

export type InvoiceCycleMapper = { [key in InvoiceCycle]: string }

export const invoiceCycleI18nMapper: InvoiceCycleMapper = {
  [InvoiceCycle.BY_ORDER]: gettext('By order'),
  [InvoiceCycle.DAILY]: gettext('Daily'),
  [InvoiceCycle.DECADE]: gettext('10-day period'),
  [InvoiceCycle.EDI]: gettext('EDI'),
  [InvoiceCycle.FORTNIGHT]: gettext('Fortnight'),
  [InvoiceCycle.MONTHLY]: gettext('Monthly'),
  [InvoiceCycle.WEEKLY]: gettext('Weekly'),
}

export const getInvoiceCycleTranslation = (invoiceCycle: InvoiceCycle) =>
  invoiceCycleI18nMapper[invoiceCycle]

export const APPLI_URL = {
  AUTH: {
    LOGIN: '/accounts/login/',
  },
  BUYER: {
    ANALYTICS: `/${UserType.BUYER}/analytics/`,
    CHAT: `/${UserType.BUYER}/chat/`,
    INVOICING: {
      BASE: `/${UserType.BUYER}/invoicing/`,
      CREDIT_NOTES: `/${UserType.BUYER}/invoicing/credit-notes/`,
      INVOICES: `/${UserType.BUYER}/invoicing/invoices/`,
    },
    MARKET: {
      BASE: `/${UserType.BUYER}/market/`,
      LIVE: `/${UserType.BUYER}/market/live/`,
      PRESALES: `/${UserType.BUYER}/market/presales/`,
    },
    ORDERS: {
      BASE: `/${UserType.BUYER}/orders/`,
      MY_ORDERS: `/${UserType.BUYER}/orders/my-orders/`,
      MY_CLAIMS: `/${UserType.BUYER}/orders/my-claims/`,
    },
    SUPPLIERS: {
      ADD: `/${UserType.BUYER}/suppliers/add/`,
      BASE: `/${UserType.BUYER}/suppliers/`,
      EDIT: `/${UserType.BUYER}/suppliers/edit/`,
    },
  },
  SELLER: {
    ANALYTICS: `/${UserType.SELLER}/analytics/`,
    CHAT: `/${UserType.SELLER}/chat/`,
    CUSTOMERS: {
      ADD: `/${UserType.SELLER}/customers/add/`,
      BASE: `/${UserType.SELLER}/customers/`,
      EDIT: `/${UserType.SELLER}/customers/edit/`,
    },
    INVOICING: {
      BASE: `/${UserType.SELLER}/invoicing/`,
      CREDIT_NOTES: `/${UserType.SELLER}/invoicing/credit-notes/`,
      INVOICES: `/${UserType.SELLER}/invoicing/invoices/`,
    },
    MARKET: {
      BASE: `/${UserType.SELLER}/market/`,
      CUSTOMERS_CATALOG: `/${UserType.SELLER}/market/live/customers-catalog/`,
      LIVE: `/${UserType.SELLER}/market/live/`,
      LIVE_CATALOG: `/${UserType.SELLER}/market/live/catalog/`,
      LIVE_CATALOG_MAPPING: `/${UserType.SELLER}/market/live/catalog/mapping/`,
      PRESALES: `/${UserType.SELLER}/market/presales/`,
    },
    ORDERS: {
      BASE: `/${UserType.SELLER}/orders/`,
      NEW_ORDER: `/${UserType.SELLER}/orders/new-order/`,
      PREPARE: `/${UserType.SELLER}/orders/:orderId/:buyerCompanyId?`,
      ADD: `/${UserType.SELLER}/orders/add/`,
      EDIT: `/${UserType.SELLER}/orders/edit/:orderId/`,
    },
    PURCHASE_ORDERS: `/${UserType.SELLER}/purchase-orders/`,
    PRESALE: {
      ADD: `/${UserType.SELLER}/presale/add/`,
      BASE: `/${UserType.SELLER}/presale/`,
      DUPLICATE: `/${UserType.SELLER}/presale/duplicate/`,
      EDIT: `/${UserType.SELLER}/presale/edit/`,
    },
    PRICE_CONFIGURATION: `/${UserType.SELLER}/price-configuration/`,
    PRODUCT: {
      BASE: `/${UserType.SELLER}/product/`,
      ADD: `/${UserType.SELLER}/product/add/`,
      EDIT: `/${UserType.SELLER}/product/edit/`,
      DUPLICATE: `/${UserType.SELLER}/product/duplicate/`,
    },
    STOCK_MANAGEMENT: `/${UserType.SELLER}/stock-management/`,
    MAPPING: {
      BASE: `/${UserType.SELLER}/mapping/`,
      PURCHASES: `${UserType.SELLER}/mapping/purchases/`,
    },
    MANAGEMENT: {
      BASE: `/${UserType.SELLER}/management/`,
    },
  },
}

export const NO_PREPARATION_ID = 20

export const API_URL = ((window as any).API_URL as string) || '/api'

/**
 * @deprecated use src/type/spitypes.ts instead.
 */
export const SELLER_PRODUCT_INSTANCE_TYPES = {
  BASIC: 'basic',
  PRESALE: 'presale',
}

export const BASKET_PX_HEIGHT = '66px'

export const API_DEFAULT_LIMIT = 25

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const ORDER_ITEMS_DEFAULT_ORDERING: OrderItemsSortBy[] = [
  'product_name_or_line_identifier',
  'calibre',
  'package_quantity_unit',
  'package_quantity',
]

export const ENV_PRODUCTION = 'PRODUCTION'

export const DEFAULT_Q_DEBOUNCE = 1000

type TranslatedShortDay = 'T' | 'D' | 'J' | 'G'

export const localeShortDays: Record<AppLocale, TranslatedShortDay> = {
  [AppLocale.DE]: 'T',
  [AppLocale.EN]: 'D',
  [AppLocale.FR]: 'J',
  [AppLocale.IT]: 'G',
}

export const ERROR_FAIL_TO_RETRIEVE_CURRENT_MEMBERSHIP_FROM_SESSION_STORAGE =
  'Fail to set or retrieve the current membership id from the session storage.'

export const CURRENT_MEMBERSHIP_ID_QUERY_PARAM = 'currentMembershipId'

export const APP_LANGUAGES: Record<AppLocale, string> = {
  [AppLocale.DE]: gettext('German'),
  [AppLocale.EN]: gettext('English'),
  [AppLocale.FR]: gettext('French'),
  [AppLocale.IT]: gettext('Italian'),
}

export const COUNTRY_CODES: Record<AppLocale, CountryCode> = {
  [AppLocale.DE]: 'DE',
  [AppLocale.EN]: 'GB',
  [AppLocale.FR]: 'FR',
  [AppLocale.IT]: 'IT',
}

export const TO_CONFIRM_ORIGIN_ID = 658
